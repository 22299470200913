import DeleteIcon from './DeleteIcon'
import { useMemo, useState } from 'react'
import ActiveIcon from './ActiveIcon'
import EditIcon from './EditIcon'
import SaveIcon from './SaveIcon'
import { useDispatch, useSelector } from 'react-redux'
import { editCastName, removeCast, setCastIsActive } from '../../redux/slices/recastSlice'

export default function CastItem({ cast, handleSelect, selectedCastId, selectedCastGroupId, selectedCastTypeId }) {
  const { groups } = useSelector((state) => state.recast)

  const dispatch = useDispatch()

  const [isEdit, setIsEdit] = useState(false)
  const [newName, setNewName] = useState(undefined)

  /* ****************** JSX / Variables ****************** */
  const liClassName = `p-4 cursor-pointer hover:bg-indigo-500 ${selectedCastId === cast.id ? 'bg-indigo-200' : ''}`

  const selectedCastGroup = useMemo(() => groups.find((group) => group.id === selectedCastGroupId), [groups, selectedCastGroupId])
  const isActive = !!selectedCastGroup?.cast_ids?.find((castId) => castId === cast.id)
  const isDisabled = !selectedCastGroup?.cast_type_ids?.find((typeId) => selectedCastTypeId === typeId)

  /* ****************** Functions ****************** */
  const handleDelete = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    ev.currentTarget.blur()
    dispatch(removeCast({ id: cast.id }))
  }

  const handleEditName = () => {
    dispatch(editCastName({ id: cast.id, name: newName }))
  }

  const handleInputEnterPress = (ev) => {
    if (ev.key === 'Enter') {
      handleEditName()
    }
  }

  const handleActive = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    ev.currentTarget.blur()
    handleSelect(cast.id)
    dispatch(setCastIsActive({ id: cast.id, selectedCastGroupId, isActive: !isActive, selectedCastTypeId }))
  }

  const handleSelectCast = (ev) => {
    handleSelect(Number(ev.target.getAttribute('data-cast-id')))
  }

  const handleIsEdit = () => {
    setIsEdit((prevState) => !prevState)
  }

  const handleInputChange = (ev) => {
    setNewName(ev.currentTarget.value)
  }

  /* ****************** JSX ****************** */
  const castNameJSX = isEdit ? (
    <input className="w-full px-2" placeholder={cast.name} onChange={handleInputChange} onKeyPress={handleInputEnterPress} />
  ) : (
    cast.name
  )

  /* ****************** Use Effects ****************** */

  /* ****************** Render ****************** */
  return (
    <li key={`${cast.name}-${cast.id}`} className={liClassName} data-cast-id={cast.id} onClick={handleSelectCast}>
      <div className="flex " data-cast-id={cast.id}>
        <div className="flex-none" data-cast-id={cast.id}>
          <ActiveIcon type="single" handleClick={handleActive} isActive={isActive} isDisabled={isDisabled} />
        </div>
        <div className="self-center flex-auto w-full pl-4" data-cast-id={cast.id}>
          {castNameJSX}
        </div>
        <div className="self-center flex-none" data-cast-id={cast.id}>
          {isEdit && <SaveIcon handleClick={handleEditName} />}
          <EditIcon handleClick={handleIsEdit} isEdit={isEdit} />
          <DeleteIcon handleClick={handleDelete} isDisabled={isActive} />
        </div>
      </div>
    </li>
  )
}
