import tripleCubesActive from '../../common/assets/demoblox_cubes.png'
import singleCubesActive from '../../common/assets/demoblox_cube.png'
import tripleCubesInactive from '../../common/assets/demoblox_cubes_inactive.png'
import singleCubesInactive from '../../common/assets/demoblox_cube_inactive.png'

export default function ActiveIcon({ iconType, handleClick, isActive, isDisabled }) {
  const iconJSX =
    iconType === 'triple' ? (
      <img className={`h-auto w-[45px]`} src={isActive ? tripleCubesActive : tripleCubesInactive} alt="" />
    ) : (
      <img className={`h-auto w-[25px]`} src={isActive ? singleCubesActive : singleCubesInactive} alt="" />
    )
  return (
    <button
      type="button"
      className={`inline-flex items-center p-1 border border-transparent shadow-sm hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
        isActive ? 'text-indigo-700 hover:text-white' : 'text-gray-400'
      } ${isDisabled && 'disabled:opacity-10 cursor-not-allowed'}`}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {iconJSX}
    </button>
  )
}
