import { Popover, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import cubes from '../../common/assets/demoblox_cubes.png'
import logoFull from '../../common/assets/DemoBloxLogo_BW.png'
import cubesInactive from '../../common/assets/demoblox_cubes_inactive.png'
import classNames from '../../common/helpers/classNames'
import GroupSelector from './GroupSelector'
import BlueButton from './BlueButton'
import { useSelector, useDispatch } from 'react-redux'
import { setIsRecastActive, setIsRecastHints } from '../../redux/slices/recastSlice'
import { InformationCircleIcon } from '@heroicons/react/outline'

export default function ExtensionButton() {
  const [show, setShow] = useState(false)

  const { isRecastActive, isRecastsHints } = useSelector((state) => state.recast)

  const dispatch = useDispatch()

  const handleActiveClick = () => {
    dispatch(setIsRecastActive())
  }

  const handleHintClick = () => {
    dispatch(setIsRecastHints())
  }

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            onClick={() => setShow((prevState) => !prevState)}
            className={classNames(
              show ? 'text-gray-900' : 'text-gray-500',
              'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            )}
          >
            <img src={isRecastActive ? cubes : cubesInactive} className="h-9" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-xs px-2 mt-3 transform -translate-x-72 sm:px-0">
              <div className="overflow-hidden bg-center bg-cover rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-extension-image">
                <div className="grid gap-6 px-5 py-6 text-white sm:gap-8 sm:p-8">
                  <div className="flex justify-between">
                    <img src={logoFull} className="h-6" />
                    <BlueButton handleClick={handleActiveClick} text={isRecastActive ? 'Turn Off' : 'Turn On'} isActive={isRecastActive} />
                  </div>
                  <div>
                    <div className="mb-4 font-bold text-center">Active Group</div>
                    <div>
                      <GroupSelector />
                    </div>
                    <div className="mt-6 text-xs text-gray-500">*Mimics DemoBlox chrome extenstion app*</div>
                    <div className="flex justify-center mt-4">
                      <InformationCircleIcon onClick={handleHintClick} className={classNames(isRecastsHints ? 'text-blue-500' : '', 'w-5 h-5')} />
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
