import { useSelector } from 'react-redux'
import classNames from '../../common/helpers/classNames'
import EmptyTable from './EmptyTable'
import { faker } from '@faker-js/faker'

const now = new Date()
const randomizer = (visits) => faker.datatype.number({ min: 0, max: visits ? visits : 1000 })
const transactions = [
  {
    date: `${new Date(now.getFullYear(), now.getMonth(), now.getDate()).toDateString()}`,
    visits: 157,
    newUsers: randomizer(157),
    lastWeek: randomizer(),
  },
  {
    date: `${new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toDateString()}`,
    visits: 375,
    newUsers: randomizer(375),
    lastWeek: randomizer(),
  },
  {
    date: `${new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2).toDateString()}`,
    visits: 684,
    newUsers: randomizer(684),
    lastWeek: randomizer(),
  },
  {
    date: `${new Date(now.getFullYear(), now.getMonth(), now.getDate() - 3).toDateString()}`,
    visits: 233,
    newUsers: randomizer(233),
    lastWeek: randomizer(),
  },
  {
    date: `${new Date(now.getFullYear(), now.getMonth(), now.getDate() - 4).toDateString()}`,
    visits: 547,
    newUsers: randomizer(547),
    lastWeek: randomizer(),
  },
  {
    date: `${new Date(now.getFullYear(), now.getMonth(), now.getDate() - 5).toDateString()}`,
    visits: 491,
    newUsers: randomizer(491),
    lastWeek: randomizer(),
  },
  {
    date: `${new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6).toDateString()}`,
    visits: 771,
    newUsers: randomizer(771),
    lastWeek: randomizer(),
  },
]

export default function UserActivities() {
  const { isRecastActive } = useSelector((state) => state.recast)

  const tableJSX = (
    <table className="min-w-full divide-y divide-gray-300 h-96">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-6 whitespace-nowrap py-3.5 pl-4 pr-3 text-left">
            Date
          </th>
          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
            Pages / Visits
          </th>
          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
            New User
          </th>
          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
            Last Week
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {transactions.map((transaction) => (
          <tr key={transaction.id}>
            <td className="px-2 py-3 pl-4 pr-3 text-sm text-gray-900 whitespace-nowrap sm:pl-6">{transaction.date}</td>
            <td className="px-2 py-3 text-sm text-gray-900 whitespace-nowrap">{transaction.visits}</td>
            <td className="px-2 py-3 text-sm text-gray-900 whitespace-nowrap">{transaction.newUsers}</td>
            <td className="px-2 py-3 text-sm text-gray-900 whitespace-nowrap">{transaction.lastWeek}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
  return (
    <div className="">
      <div className="justify-between sm:flex">
        <h1 className="text-xl font-semibold text-gray-900">Account Activities</h1>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center px-4 py-2 text-sm text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto hover:cursor-not-allowed"
            disabled
          >
            Exports
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className={classNames(isRecastActive ? 'shadow ring-1 ring-black ring-opacity-5 md:rounded-lg' : '', 'overflow-hidden')}>
              {isRecastActive ? tableJSX : <EmptyTable />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
