/* This example requires Tailwind CSS v2.0+ */
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import classNames from '../../common/helpers/classNames'
import { useSelector } from 'react-redux'
import DateRangeSelector from '../atoms/DateRangeSelector'

export default function SalesStatsRow() {
  const { isRecastActive, dateRange } = useSelector((state) => state.recast)
  let months = 1

  if (dateRange === 'This Month') months = 1
  if (dateRange === 'Last 2 Months') months = 2
  if (dateRange === 'Last 3 Months') months = 3

  const stats = isRecastActive
    ? [
        { name: 'Total Sales', stat: `$${175 * months}k`, previousStat: `$85k`, change: `${206 * months}%`, changeType: 'increase' },
        { name: 'Revenue', stat: `$${300 * months}k`, previousStat: `$150k`, change: `${200 * months}%`, changeType: 'increase' },
        { name: 'New Customers', stat: `$${10 * months}k`, previousStat: `$5k`, change: `${200 * months}%`, changeType: 'increase' },
      ]
    : [
        { name: 'Total Sales', stat: '$0k', previousStat: '$0k', change: '0%', changeType: 'decrease' },
        { name: 'Revenue', stat: '$0k', previousStat: '$0k', change: '0%', changeType: 'decrease' },
        { name: 'New Customers', stat: '0', previousStat: '0', change: '0%', changeType: 'decrease' },
      ]
  return (
    <>
      <div className="flex items-center">
        <h3 className="text-lg font-medium leading-6 text-gray-900 mr-6">Stats</h3>
        <DateRangeSelector />
      </div>
      <dl className="grid grid-cols-1 mt-5 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
              </div>

              <div
                className={classNames(
                  item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowSmUpIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" aria-hidden="true" />
                ) : (
                  <ArrowSmDownIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" aria-hidden="true" />
                )}

                <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </>
  )
}
