import ActiveUsers from '../components/atoms/ActiveUsers'
import { SimpleBarChart } from '../components/atoms/charts/SimpleBarChart'
import { SimplePieChart } from '../components/atoms/charts/SimplePieCart'
import Overview from '../components/atoms/Overview'
import RecentOpportunities from '../components/atoms/RecentOpportunities'
import SurgeTopics from '../components/atoms/SurgeTopics'
import UserActivities from '../components/atoms/UserActivities'
import UserConversionRate from '../components/atoms/UserConversionRate'
import NewCustomersProgressBar from '../components/molecules/NewCustomersProgressBar'
import OrdersProgressBar from '../components/molecules/OrdersProgressBar'
import SalesStatsRow from '../components/molecules/SalesStatsRow'
import TodaysSalesProgressBar from '../components/molecules/TodaysSalesProgressBar'

export default function DemoPageOne() {
  return (
    <div>
      <div className="mb-4">
        <SalesStatsRow />
      </div>
      <div className="flex gap-4 mb-4">
        <div className="w-2/3 ">
          <SimpleBarChart />
        </div>
        <div className="w-1/3">
          <Overview />
        </div>
      </div>
      <div className="flex gap-4 mb-4">
        <div className="w-1/4">
          <SimplePieChart />
        </div>
        <div className="w-3/4">
          <RecentOpportunities />
        </div>
      </div>
      <div className="flex gap-4 mb-4">
        <div className="w-1/3">
          <TodaysSalesProgressBar />
        </div>
        <div className="w-1/3">
          <OrdersProgressBar />
        </div>
        <div className="w-1/3">
          <NewCustomersProgressBar />
        </div>
      </div>
      <div className="flex gap-4 mb-4">
        <div className="w-1/2">
          <UserActivities />
        </div>
        <div className="w-1/2">
          <ActiveUsers />
        </div>
      </div>
      <div className="flex gap-4 mb-4">
        <div className="w-1/2">
          <UserConversionRate />
        </div>
        <div className="w-1/2">
          <SurgeTopics />
        </div>
      </div>
    </div>
  )
}
