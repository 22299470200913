import { createSlice } from '@reduxjs/toolkit'
import {
  gdsConverationRate,
  gdsConverationRatePeopleName,
  gdsSampleSurgeTopics,
  gdsActiveUserEmails,
  gdsActiveUserNames,
  gdsActiveUserTitles,
  gdsOpportuntiesCompany,
  gdsOpportuntiesCustomerName,
  gdsOpportuntiesTotalSales,
  gdsOpportuntiesStatus,
} from '../../common/helpers/gds'

const initialState = {
  groups: [
    {
      id: 1,
      name: 'Golden Data Set',
      is_active: true,
      cast_type_ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      cast_ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
  ],
  casts: [
    { id: 1, company_id: 1, user_id: 1, name: 'Surge Topics', cast_values: gdsSampleSurgeTopics, cast_type_id: 1, is_active: true },
    { id: 2, company_id: 1, user_id: 1, name: 'Sales Rep Names', cast_values: gdsConverationRatePeopleName, cast_type_id: 2, is_active: true },
    { id: 3, company_id: 1, user_id: 1, name: 'Sales Rep Conversion', cast_values: gdsConverationRate, cast_type_id: 3, is_active: true },
    { id: 4, company_id: 1, user_id: 1, name: 'Active Contact Names', cast_values: gdsActiveUserNames, cast_type_id: 4, is_active: true },
    { id: 5, company_id: 1, user_id: 1, name: 'Active Contact Emails', cast_values: gdsActiveUserEmails, cast_type_id: 5, is_active: true },
    { id: 6, company_id: 1, user_id: 1, name: 'Active Contact Titles', cast_values: gdsActiveUserTitles, cast_type_id: 6, is_active: true },
    { id: 7, company_id: 1, user_id: 1, name: 'Opportunity Companies', cast_values: gdsOpportuntiesCompany, cast_type_id: 7, is_active: true },
    {
      id: 8,
      company_id: 1,
      user_id: 1,
      name: 'Opportunity Contact',
      cast_values: gdsOpportuntiesCustomerName,
      cast_type_id: 8,
      is_active: true,
    },
    {
      id: 9,
      company_id: 1,
      user_id: 1,
      name: 'Opportunity ARR',
      cast_values: gdsOpportuntiesTotalSales,
      cast_type_id: 9,
      is_active: true,
    },
    { id: 10, company_id: 1, user_id: 1, name: 'Opportunity Stage', cast_values: gdsOpportuntiesStatus, cast_type_id: 10, is_active: true },
  ],
  castTypes: [
    { id: 7, name: 'Opportunity Company', company_id: 1 },
    { id: 8, name: 'Opportunity Contact', company_id: 1 },
    { id: 9, name: 'Opportunity ARR', company_id: 1 },
    { id: 10, name: 'Opportunity Stage', company_id: 1 },
    { id: 4, name: 'Active Contact Name', company_id: 1 },
    { id: 5, name: 'Active Contact Email', company_id: 1 },
    { id: 6, name: 'Active Contact Title', company_id: 1 },
    { id: 2, name: 'Sales Rep Name', company_id: 1 },
    { id: 3, name: 'Sales Rep Conversion', company_id: 1 },
    { id: 1, name: 'Surge Topics', company_id: 1 },
  ],
  isRecastActive: true,
  isRecastsHints: false,
  dateRange: 'This Month',
}

export const recastSlice = createSlice({
  name: 'recast',
  initialState,
  reducers: {
    addCast(state, { payload }) {
      state.casts.push({
        id: new Date().getTime(),
        company_id: 1,
        user_id: 1,
        name: payload.name,
        cast_values: [],
        cast_type_id: payload.castTypeId,
        isActive: payload.isActive,
      })
    },
    addCastValue(state, { payload }) {
      for (const cast of state.casts) {
        if (cast.id === payload.selectedCastId) cast.cast_values.push(payload.newCastValueName)
      }
    },
    addGroup(state, { payload }) {
      state.groups.push({
        id: new Date().getTime(),
        name: payload.name,
        is_active: false,
        cast_type_ids: [],
        cast_ids: [],
      })
    },
    removeGroup(state, { payload }) {
      const indexToRemove = state.groups.map((group) => group.id).indexOf(payload)
      state.groups.splice(indexToRemove, 1)
    },
    editGroupName(state, { payload }) {
      state.groups.find((group) => group.id === payload.id).name = payload.name
    },
    setGroupIsActive(state, { payload }) {
      state.groups.forEach((group, i) => {
        if (group.id === payload.id) {
          state.groups[i] = { ...group, ...{ is_active: payload.isActive } }

          // set GDS when other group is active
          if (!payload.isActive) {
            state.groups[0].is_active = true
          }
        } else {
          state.groups[i] = { ...group, ...{ is_active: false } }
        }
      })

      let newActiveGroup = state.groups.find((group) => group.id === payload.id)
      if (!newActiveGroup.is_active) {
        newActiveGroup = state.groups[0]
      }

      const castsToActivate = newActiveGroup.cast_ids

      state.casts = state.casts.map((cast) => ({ ...cast, is_active: false }))
      state.casts = state.casts.map((cast) => (castsToActivate.includes(cast.id) ? { ...cast, is_active: true } : cast))
    },
    setTypeIsActive(state, { payload }) {
      if (payload.isActive) {
        state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_type_ids.push(payload.id)

        const castToAdd = state.casts.find((cast) => cast.cast_type_id === payload.id && cast.is_active)

        if (castToAdd) {
          state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_ids.push(castToAdd.id)
        }
      } else {
        const indexToRemoveType = state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_type_ids.indexOf(payload.id)
        state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_type_ids.splice(indexToRemoveType, 1)

        const toRemoveCast = state.casts.find((cast) => cast.cast_type_id === payload.id && cast.is_active)

        if (toRemoveCast) {
          const indexToRemoveCast = state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_ids.indexOf(toRemoveCast.id)
          state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_ids.splice(indexToRemoveCast, 1)
        }
      }
    },
    setCastIsActive(state, { payload }) {
      const currentActiveCast = state.casts.find((cast) => cast.cast_type_id === payload.selectedCastTypeId && cast.is_active)
      console.log(payload)
      if (currentActiveCast) {
        const indexToRemove = state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_ids.indexOf(currentActiveCast.id)
        state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_ids.splice(indexToRemove, 1)
        state.casts.find((cast) => cast.id === currentActiveCast.id).is_active = false
      }

      if (payload.isActive) {
        state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_ids.push(payload.id)
        state.casts.find((cast) => cast.id === payload.id).is_active = true
      }
      // else {
      //   const indexToRemove = state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_ids.indexOf(payload.id)
      //   state.groups.find((group) => group.id === payload.selectedCastGroupId).cast_ids.splice(indexToRemove, 1)
      //   state.casts.find((cast) => cast.id === payload.id).is_active = false
      // }
    },
    editCastName(state, { payload }) {
      state.casts.find((cast) => cast.id === payload.id).name = payload.name
    },
    removeCast(state, { payload }) {
      state.casts = state.casts.filter((cast) => cast.id !== payload.id)
    },
    removeCastValue(state, { payload }) {
      const cast = state.casts.find((cast) => cast.id === payload.id)
      cast.cast_values = cast.cast_values.filter((value) => value !== payload.value)
    },
    setCastValues(state, { payload }) {
      state.casts.find((cast) => cast.id === payload.id).cast_values = payload.castValues
    },
    setIsRecastActive(state) {
      state.isRecastActive = !state.isRecastActive
    },
    setIsRecastHints(state) {
      state.isRecastsHints = !state.isRecastsHints
    },
    setDateRange(state, { payload }) {
      state.dateRange = payload
    },
    resetRecasts() {
      return initialState
    },
  },
})

export const {
  addCast,
  addCastValue,
  addGroup,
  removeGroup,
  editGroupName,
  setGroupIsActive,
  setTypeIsActive,
  setCastIsActive,
  editCastName,
  removeCast,
  removeCastValue,
  setCastValues,
  setIsRecastActive,
  setIsRecastHints,
  setDateRange,
  resetRecasts,
} = recastSlice.actions

export default recastSlice.reducer
