import { CheckIcon } from '@heroicons/react/outline'

export default function SaveIcon({ handleClick, castId }) {
  return (
    <button
      type="button"
      className="inline-flex items-center p-1 mx-2 text-white bg-green-400 border border-transparent rounded-full shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      data-cast-id={castId}
      onClick={handleClick}
    >
      <CheckIcon className="w-3 h-3" aria-hidden="true" />
    </button>
  )
}
