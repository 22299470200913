import { useState } from 'react'
import LoadingAnimation from './LoadingAnimation'
import { useDispatch } from 'react-redux'
import { addGroup } from '../../redux/slices/recastSlice'

export default function NewCastGroupItem() {
  const [newCastGroupName, setNewCastGroupName] = useState('')
  const dispatch = useDispatch()

  /* ****************** Mutation Hook ****************** */
  const createCastGroup = () => {
    dispatch(addGroup({ name: newCastGroupName }))
    setNewCastGroupName('')
  }

  /* ****************** JSX / Variables ****************** */
  const liClassName = 'p-4'
  const inputClassName = `w-full px-5 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300 rounded-md ${
    createCastGroup.isLoading ? 'ring-green-500' : ''
  } ${createCastGroup.isError ? 'ring-red-500' : ''}`

  /* ****************** Functions ****************** */
  const handleChange = (ev) => {
    setNewCastGroupName(ev.currentTarget.value)
  }

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      dispatch(addGroup({ name: newCastGroupName }))
      setNewCastGroupName('')
    }
  }

  /* ****************** Use Effects ****************** */

  /* ****************** Render ****************** */
  return (
    <li key="new-cast-entry" className={liClassName}>
      {createCastGroup.isLoading ? <LoadingAnimation /> : null}
      {createCastGroup.isLoading ? null : (
        <input
          className={inputClassName}
          type="text"
          onChange={handleChange}
          value={newCastGroupName}
          placeholder={newCastGroupName === '' ? 'Add New Cast Group' : undefined}
          onKeyPress={handleKeyPress}
        />
      )}
    </li>
  )
}
