/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux'
import classNames from '../../common/helpers/classNames'
import EmptyTable from './EmptyTable'

export default function ActiveUsers() {
  const { casts, isRecastActive, isRecastsHints, groups } = useSelector((state) => state.recast)

  const nameTypeId = 4
  const emailTypeId = 5
  const titleTypeId = 6

  const activeGroup = groups.find((group) => group.is_active)
  const names = casts.find((cast) => cast.cast_type_id === nameTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []
  const emails =
    casts.find((cast) => cast.cast_type_id === emailTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []
  const titles =
    casts.find((cast) => cast.cast_type_id === titleTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []

  const data = []

  if (isRecastActive) {
    for (const name of names) {
      data.push({ name })
    }

    for (const [i, email] of emails.entries()) {
      data[i] = { ...data[i], ...{ email } }
    }

    for (const [i, title] of titles.entries()) {
      data[i] = { ...data[i], ...{ title } }
    }
  }

  const tableJSX = (
    <table className="min-w-full divide-y bg-white divide-gray-300 h-96">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Name
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Email
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Title
          </th>
          {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
            <span className="sr-only">Edit</span>
          </th> */}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((person) => (
          <tr key={person.email}>
            <td className="py-2 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
              <div className="flex items-center">
                <div className="flex-shrink-0 w-10 h-10">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-500 rounded-full">
                    <h3 className="text-white">{person?.name[0]}</h3>
                  </div>
                </div>
                <div className="ml-4">
                  <div className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900')}>{person.name}</div>
                </div>
              </div>
            </td>
            <td className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900', 'px-3 py-4 text-sm  whitespace-nowrap')}>{person.email}</td>
            <td className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900', 'px-3 py-4 text-sm  whitespace-nowrap')}>{person.title}</td>
            {/* <td className="relative py-4 pl-3 pr-4 text-sm text-right whitespace-nowrap sm:pr-6">
              <a className="text-indigo-600 cursor-not-allowed hover:text-indigo-900">Edit</a>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  )

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Active Contacts</h1>
        </div>
      </div>
      <div className="flex flex-col mt-4">
        <div className="overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className={classNames(isRecastActive ? 'shadow ring-1 ring-black ring-opacity-5 md:rounded-lg' : '', 'overflow-hidden')}>
              {isRecastActive ? tableJSX : <EmptyTable />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
