import ProgressBar from '../atoms/ProgressBar'
import { useSelector } from 'react-redux'

export default function TodaysSalesProgressBar() {
  const { isRecastActive } = useSelector((state) => state.recast)

  return (
    <div className="p-4 bg-white rounded-md shadow-md">
      <h4>Open Opps</h4>
      <ProgressBar barColor="bg-indigo-500" completePercent={isRecastActive ? 55 : 5} />
    </div>
  )
}
