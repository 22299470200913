import { useState } from 'react'
import LoadingAnimation from './LoadingAnimation'
import { useDispatch } from 'react-redux'
import { addCast } from '../../redux/slices/recastSlice'

export default function NewCastItem({ selectedCastTypeId }) {
  const dispatch = useDispatch()
  const [newCastName, setNewCastName] = useState('')

  /* ****************** Mutation Hook ****************** */
  const createCast = () => {
    const payload = {
      name: newCastName,
      castValues: [],
      isActive: false,
      castTypeId: selectedCastTypeId,
    }

    dispatch(addCast(payload))
    setNewCastName('')
  }

  /* ****************** JSX / Variables ****************** */
  const liClassName = 'p-4'
  const inputClassName = `w-full px-5 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300 rounded-md`

  /* ****************** Functions ****************** */
  const handleChange = (ev) => {
    setNewCastName(ev.currentTarget.value)
  }

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      const payload = {
        name: newCastName,
        castValues: [],
        isActive: false,
        castTypeId: selectedCastTypeId,
      }

      dispatch(addCast(payload))
      setNewCastName('')
    }
  }

  /* ****************** Use Effects ****************** */

  /* ****************** Render ****************** */
  return (
    <li key="new-cast-entry" className={liClassName}>
      {createCast.isLoading ? <LoadingAnimation /> : null}
      {createCast.isLoading ? null : (
        <input
          className={inputClassName}
          type="text"
          onChange={handleChange}
          value={newCastName}
          placeholder={newCastName === '' ? 'Add New Cast' : undefined}
          onKeyPress={handleKeyPress}
        />
      )}
    </li>
  )
}
