import { useSelector } from 'react-redux'
import classNames from '../../common/helpers/classNames'
import EmptyTable from './EmptyTable'

export default function RecentOpportunities() {
  const { casts, isRecastActive, isRecastsHints, groups } = useSelector((state) => state.recast)

  const companyTypeId = 7
  const customerNameTypeId = 8
  const totalSalesTypeId = 9
  const statusTypeId = 10

  const activeGroup = groups.find((group) => group.is_active)

  const companies =
    casts.find((cast) => cast.cast_type_id === companyTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []
  const customers =
    casts.find((cast) => cast.cast_type_id === customerNameTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []
  const totalSales =
    casts.find((cast) => cast.cast_type_id === totalSalesTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []
  const statuses =
    casts.find((cast) => cast.cast_type_id === statusTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []

  const data = []

  if (isRecastActive) {
    for (const [i] of companies.entries()) {
      let dateNow = new Date()
      dateNow.setDate(dateNow.getDate() + i * 7)
      data.push({ date: dateNow.toLocaleDateString() })
    }

    for (const [i, company] of companies.entries()) {
      data[i] = { ...data[i], ...{ company } }
    }

    for (const [i, customer] of customers.entries()) {
      data[i] = { ...data[i], ...{ customer } }
    }

    for (const [i, totalSale] of totalSales.entries()) {
      data[i] = { ...data[i], ...{ totalSale } }
    }

    for (const [i, status] of statuses.entries()) {
      data[i] = { ...data[i], ...{ status } }
    }
  }

  const tableJSX = (
    <table className="min-w-full divide-y divide-gray-300 bg-white h-96">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Closed Date
          </th>
          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
            Company
          </th>
          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
            Contact
          </th>
          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
            ARR
          </th>
          <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
            Stage
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((d, i) => {
          let dateNow = new Date()
          dateNow.setDate(dateNow.getDate() + i * 7)

          return (
            <tr key={i}>
              <td className="py-2 pl-4 pr-3 text-sm text-gray-900 whitespace-nowrap sm:pl-6">{dateNow.toLocaleDateString()}</td>
              <td className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900', 'px-2 py-2 text-sm whitespace-nowrap')}>{d.company}</td>
              <td className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900', 'px-2 py-2 text-sm whitespace-nowrap')}>{d.customer}</td>
              <td className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900', 'px-2 py-2 text-sm whitespace-nowrap')}>
                ${d.totalSale}
              </td>
              <td className="px-2 py-2 text-sm text-gray-900 whitespace-nowrap">{d.status}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Open Opportunities</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            disabled
            className="inline-flex items-center justify-center px-4 py-2 text-sm text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto hover:cursor-not-allowed"
          >
            Export
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-4">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className={classNames(isRecastActive ? 'shadow ring-1 ring-black ring-opacity-5 md:rounded-lg' : '', 'overflow-hidden')}>
              {isRecastActive ? tableJSX : <EmptyTable />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
