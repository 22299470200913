import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { faker } from '@faker-js/faker'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function subtractMonths(numOfMonths, date = new Date()) {
  date.setMonth(date.getMonth() - numOfMonths)

  return date
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Sales Month Over Month',
    },
  },
}

const randomizer = (min, max) => faker.datatype.number({ min, max })
const previousYearData = [randomizer(0, 300), randomizer(300, 550), randomizer(550, 700), randomizer(1000, 1600)]
const currentYearData = [randomizer(250, 600), randomizer(600, 1000), randomizer(1000, 1300), randomizer(1500, 2000)]

export function SimpleBarChart() {
  const { isRecastActive, dateRange } = useSelector((state) => state.recast)

  // const isGDS = groups.find((group) => group.id === 1 && group.is_active)
  const [previousYear, setPreviousYear] = useState([previousYearData[0]])
  const [currentYear, setCurrentYear] = useState([currentYearData[0]])
  const [months, setMonths] = useState(1)

  let labels = []

  if (dateRange === 'This Month') {
    labels.push(subtractMonths(0).toLocaleString('default', { month: 'long' }))
    if (months !== 1) setMonths(1)
  }

  if (dateRange === 'Last 2 Months') {
    for (let i = 0; i < 2; i++) {
      labels.push(subtractMonths(i).toLocaleString('default', { month: 'long' }))
    }
    if (months !== 2) setMonths(2)
  }

  if (dateRange === 'Last 3 Months') {
    for (let i = 0; i < 3; i++) {
      labels.push(subtractMonths(i).toLocaleString('default', { month: 'long' }))
    }
    if (months !== 3) setMonths(3)
  }

  useEffect(() => {
    if (months === 1 && previousYear.length !== 1 && currentYear.length !== 1) {
      setPreviousYear(previousYearData.filter((data, index) => index < months))
      setCurrentYear(currentYearData.filter((data, index) => index < months))
    }

    if (months === 2 && previousYear.length !== 2 && currentYear.length !== 2) {
      setPreviousYear(previousYearData.filter((data, index) => index < months))
      setCurrentYear(currentYearData.filter((data, index) => index < months))
    }

    if (months === 3 && previousYear.length !== 3 && currentYear.length !== 3) {
      setPreviousYear(previousYearData.filter((data, index) => index < months))
      setCurrentYear(currentYearData.filter((data, index) => index < months))
    }
  }, [previousYear, currentYear, months])

  const data = {
    labels,
    datasets: [
      {
        label: 'Previous Year',
        data: !isRecastActive ? [] : previousYear,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Current Year',
        data: !isRecastActive ? [] : currentYear,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <Bar options={options} data={data} />
    </div>
  )
}
