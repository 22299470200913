import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HomeIcon, MenuAlt2Icon, XIcon } from '@heroicons/react/outline'
import UserDropdown from '../molecules/UserDropdown'
import classNames from '../../common/helpers/classNames'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../common/assets/DemoBloxLogo_BW.png'
import AdminNav from '../molecules/AdminNav'
import ExtensionButton from '../atoms/ExtensionButton'

export default function Nav(props) {
  const { children, hidden } = props
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { pathname } = useLocation()

  const navigation = [
    { name: 'Sales Demo', href: '/', icon: HomeIcon, current: pathname.length === 1 },
    { name: 'ReCasts', href: '/recasts', icon: HomeIcon, current: pathname.match(/recasts/g) },
  ]

  /* ****************** UseEffects ****************** */

  /* ****************** Render ****************** */
  // Handle login and logout screen
  if (hidden) return children

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-slate-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    type="button"
                    className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex items-center flex-shrink-0 px-4">
                <img src={logo} alt="Workflow" />
              </div>
              <div className="flex-1 h-0 mt-5 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-600',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}
                    >
                      <item.icon className="flex-shrink-0 w-6 h-6 mr-4 text-indigo-300" aria-hidden="true" />
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow pt-5 overflow-y-auto bg-slate-800">
          <div className="flex items-center flex-shrink-0 px-4">
            <img src={logo} alt="Workflow" />
          </div>
          <div className="flex flex-col flex-1 mt-5">
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-600',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}
                >
                  <item.icon className="flex-shrink-0 w-6 h-6 mr-3 text-indigo-300" aria-hidden="true" />
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 md:pl-64">
        <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white shadow">
          <button
            type="button"
            className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
          </button>

          <div className={`flex-1 px-4 flex ${pathname.match(/admin/g) ? 'justify-between' : 'justify-end'}`}>
            {pathname.match(/admin/g) ? <AdminNav /> : null}
            <div className="flex items-center ml-4 md:ml-6">
              <div className="relative">
                <ExtensionButton />
              </div>
              {/* <UserDropdown /> */}
            </div>
          </div>
        </div>

        <main>
          <div className="py-6">
            <div className="px-4 mx-auto max-w-screen-2xl sm:px-6 md:px-8">{children}</div>
          </div>
        </main>
      </div>
    </>
  )
}
