import React, { useEffect, useState } from 'react'
import CastValueItem from '../atoms/CastValue'
import NewCastValue from '../atoms/NewCastValue'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { setCastValues } from '../../redux/slices/recastSlice'

export default function CastValueList({ castValues, selectedCastId, isCastsLoading }) {
  const dispatch = useDispatch()
  const [values, _setValues] = useState([])

  /* ****************** Functions ****************** */
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }
    const newOrder = reorder(values, result.source.index, result.destination.index)
    _setValues(newOrder)
    dispatch(setCastValues({ id: selectedCastId, castValues: newOrder }))
  }

  /* ****************** JSX ****************** */
  const castValuesJSX = values.map((value, index) => (
    <CastValueItem
      key={`${value}-${index}`}
      castValue={value}
      handleSelect={() => {}}
      selectedCastValues={() => {}}
      index={index}
      castValues={castValues}
      selectedCastId={selectedCastId}
    />
  ))

  /* ****************** Use Effects ****************** */

  useEffect(() => {
    if (castValues) {
      _setValues(castValues)
    }
  }, [castValues])

  /* ****************** Render ****************** */
  return (
    <div className="overflow-hidden bg-white rounded-md shadow">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul className="divide-y divide-gray-200" {...provided.droppableProps} ref={provided.innerRef}>
              {isCastsLoading ? 'loading...' : castValuesJSX}
              {provided.placeholder}
              <NewCastValue selectedCastId={selectedCastId} castValues={values} />
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
