/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
// const people = [
//   {
//     name: 'Lindsay Walton',
//     department: 'Optimization',
//     conversionRate: '85%',
//     image:
//       'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     name: 'Lindsay Walton',
//     department: 'Optimization',
//     conversionRate: '85%',
//     image:
//       'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     name: 'Lindsay Walton',
//     department: 'Optimization',
//     conversionRate: '85%',
//     image:
//       'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     name: 'Lindsay Walton',
//     department: 'Optimization',
//     conversionRate: '85%',
//     image:
//       'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     name: 'Lindsay Walton',
//     department: 'Optimization',
//     conversionRate: '85%',
//     image:
//       'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },

//   // More people...
// ]

import { useSelector } from 'react-redux'
import classNames from '../../common/helpers/classNames'
import EmptyTable from './EmptyTable'

export default function UserConversionRate() {
  const { casts, isRecastActive, isRecastsHints, groups } = useSelector((state) => state.recast)
  const convRateNameTypeId = 2
  const conRateTypeId = 3

  const activeGroup = groups.find((group) => group.is_active)
  const convRateNames =
    casts.find((cast) => cast.cast_type_id === convRateNameTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []
  const convRate =
    casts.find((cast) => cast.cast_type_id === conRateTypeId && cast.is_active && activeGroup?.cast_ids.includes(cast.id))?.cast_values ?? []

  const data = []

  if (isRecastActive) {
    for (const name of convRateNames) {
      data.push({ name })
    }

    for (const [i, rate] of convRate.entries()) {
      data[i] = { ...data[i], ...{ rate } }
    }
  }

  const tableJSX = (
    <table className="min-w-full divide-y divide-gray-300 h-96 bg-white">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Name
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Conversion Rate
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((person) => (
          <tr key={person.name}>
            <td className="py-2 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
              <div className="flex items-center">
                <div className="flex-shrink-0 w-10 h-10">
                  <div className="flex items-center justify-center w-10 h-10 bg-blue-500 rounded-full">
                    <h3 className="text-white">{person.name[0]}</h3>
                  </div>
                </div>
                <div className="ml-4">
                  <div className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900')}>{person.name}</div>
                </div>
              </div>
            </td>
            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
              <div className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900')}>{person.rate}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Top Sale Rep</h1>
        </div>
      </div>
      <div className="flex flex-col mt-2 ">
        <div className="overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle ">
            <div className={classNames(isRecastActive ? 'shadow ring-1 ring-black ring-opacity-5 md:rounded-lg' : '', 'overflow-hidden')}>
              {isRecastActive ? tableJSX : <EmptyTable />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
