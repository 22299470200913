/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { useSelector } from 'react-redux'

export default function Overview() {
  const { isRecastActive } = useSelector((state) => state.recast)

  const people = isRecastActive
    ? [
        {
          name: '150,000',
          handle: 'Total Visitors',
        },
        {
          name: '$30,000',
          handle: 'Monthly Profits',
        },
        {
          name: '28,000',
          handle: 'Monthly Orders',
        },
        {
          name: '1,200',
          handle: 'New Visitors',
        },
      ]
    : [
        {
          name: '0',
          handle: 'Total Visitors',
        },
        {
          name: '$0',
          handle: 'Monthly Profits',
        },
        {
          name: '0',
          handle: 'Monthly Orders',
        },
        {
          name: '0',
          handle: 'New Visitors',
        },
      ]

  return (
    <div className="h-full p-4 bg-white rounded-md shadow-md">
      <h3 className="text-xl font-semibold text-gray-900">Overview</h3>
      <div className="flow-root mt-6">
        <ul role="list" className="-my-5 divide-y divide-gray-200">
          {people.map((item) => (
            <li key={item.handle} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <h4 className="text-sm font-medium text-gray-900 truncate">{item.name}</h4>
                  <p className="text-sm text-gray-500 truncate">{item.handle}</p>
                </div>
                <div>
                  <a
                    href="#"
                    className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                  >
                    View
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
