import { useState } from 'react'
import LoadingAnimation from './LoadingAnimation'
import { useDispatch } from 'react-redux'
import { addCastValue } from '../../redux/slices/recastSlice'

export default function NewCastValue({ selectedCastId, castValues }) {
  const [newCastValueName, setNewCastValueName] = useState('')
  const dispatch = useDispatch()

  /* ****************** Mutation Hook ****************** */
  const createCastValue = () => {
    dispatch(addCastValue({ selectedCastId, newCastValueName }))
    setNewCastValueName('')
  }

  /* ****************** JSX / Variables ****************** */
  const liClassName = 'p-4'
  const inputClassName = `w-full px-5 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300 rounded-md`

  /* ****************** Functions ****************** */
  const handleChange = (ev) => {
    setNewCastValueName(ev.currentTarget.value)
  }

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      dispatch(addCastValue({ selectedCastId, newCastValueName }))
      setNewCastValueName('')
    }
  }

  /* ****************** Use Effects ****************** */

  /* ****************** Render ****************** */
  return (
    <li key="new-cast-entry" className={liClassName}>
      {createCastValue.isLoading ? <LoadingAnimation /> : null}
      {createCastValue.isLoading ? null : (
        <input
          className={inputClassName}
          type="text"
          onChange={handleChange}
          value={newCastValueName}
          placeholder={newCastValueName === '' ? 'Add New Cast Value' : undefined}
          onKeyPress={handleKeyPress}
        />
      )}
    </li>
  )
}
