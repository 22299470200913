import classNames from '../../common/helpers/classNames'

export default function BlueButton({ handleClick, text, isActive }) {
  return (
    <button
      onClick={handleClick}
      type="button"
      className={classNames(
        isActive ? 'bg-indigo-600 text-white hover:bg-indigo-700 ' : 'bg-white text-black hover:bg-indigo-200',
        'inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      )}
    >
      {text}
    </button>
  )
}
