import { useSelector } from 'react-redux'
import classNames from '../../common/helpers/classNames'
import EmptyTable from './EmptyTable'

export default function SurgeTopics() {
  const { casts, isRecastActive, isRecastsHints, groups } = useSelector((state) => state.recast)

  const activeGroup = groups.find((group) => group.is_active)
  const surgeTopicsTypeId = 1
  const activeCast = casts.find((cast) => cast.is_active && cast.cast_type_id === surgeTopicsTypeId && activeGroup?.cast_ids.includes(cast.id))

  const listJSX = (
    <ul className="px-10 py-4 overflow-auto overflow-y-auto list-disc bg-white rounded-md shadow-md h-96">
      {activeCast &&
        activeCast.cast_values.map((item) => (
          <li className="text-indigo-500">
            <p className={classNames(isRecastsHints ? 'text-blue-500' : 'text-gray-900')}>{item}</p>
          </li>
        ))}
    </ul>
  )
  return (
    <div className="h-full">
      <h1 className="text-xl font-semibold text-gray-900">Surge Topics</h1>
      <div className="mt-4">{isRecastActive ? listJSX : <EmptyTable />}</div>
    </div>
  )
}
