import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

ChartJS.register(ArcElement, Tooltip, Legend)

export function SimplePieChart() {
  const { isRecastActive } = useSelector((state) => state.recast)
  const data = {
    labels: ['MQL', 'SQL', 'Discovery', 'Tech Review', 'Contracting', 'POV'],
    datasets: [
      {
        label: '# of Votes',
        data: isRecastActive ? [12, 19, 3, 5, 2, 3] : [1, 1, 1, 1, 1, 1],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="h-full p-4 text-center bg-white rounded-md shadow-md">
      <div className="text-sm font-bold text-gray-500 mb-6">Sales Stages</div>
      <Pie data={data} />
    </div>
  )
}
