import { setDateRange } from '../../redux/slices/recastSlice'
import { useDispatch } from 'react-redux'

export default function DateRangeSelector() {
  const dispatch = useDispatch()

  return (
    <div>
      <select
        id="location"
        name="location"
        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        defaultValue="This Month"
        onChange={(e) => dispatch(setDateRange(e.target.value))}
      >
        <option>This Month</option>
        <option>Last 2 Months</option>
        <option>Last 3 Months</option>
      </select>
    </div>
  )
}
