import DeleteIcon from './DeleteIcon'
import { useState } from 'react'

import EditIcon from './EditIcon'
import SaveIcon from './SaveIcon'
import DragIcon from './DragIcon'
import { Draggable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { setCastValues } from '../../redux/slices/recastSlice'

export default function CastValueItem({ castValue, handleSelect, index, castValues, selectedCastId, moveCard }) {
  const dispatch = useDispatch()

  const [isEdit, setIsEdit] = useState(false)
  const [newName, setNewName] = useState(undefined)

  /* ****************** Mutation Hook ****************** */

  /* ****************** Functions ****************** */
  const handleInputEnterPress = (ev) => {
    if (ev.key === 'Enter') {
      const cloneValues = castValues.map((value) => value)
      cloneValues[index] = newName
      dispatch(setCastValues({ id: selectedCastId, castValues: cloneValues }))
    }
  }

  const handleDelete = () => {
    const cloneValues = castValues.map((value) => value)
    cloneValues.splice(index, 1)
    dispatch(setCastValues({ id: selectedCastId, castValues: cloneValues }))
  }

  const handleIsEdit = () => {
    setIsEdit((prevState) => !prevState)
  }

  const handleEditName = () => {
    const cloneValues = castValues.map((value) => value)
    cloneValues[index] = newName
    dispatch(setCastValues({ id: selectedCastId, castValues: cloneValues }))
  }

  const handleInputChange = (ev) => {
    setNewName(ev.currentTarget.value)
  }

  /* ****************** JSX / Variables ****************** */
  const castValueNameJSX = isEdit ? (
    <input className="px-2" placeholder={castValue} onChange={handleInputChange} onKeyPress={handleInputEnterPress} />
  ) : (
    castValue
  )

  /* ****************** Use Effects ****************** */

  /* ****************** Render ****************** */
  return (
    <Draggable key={`${castValue}-${index}`} draggableId={`${castValue}-${index}`} index={index}>
      {(provided, snapshot) => (
        <li
          className={`p-4 ${snapshot.isDragging ? 'bg-indigo-500 text-white' : 'opacity-100'}`}
          data-cast-value={castValue}
          onClick={handleSelect}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="flex">
            <div className="flex-none" {...provided.dragHandleProps}>
              <DragIcon />
            </div>
            <div className="self-center flex-auto pl-4" data-cast-id={castValue}>
              {castValueNameJSX}
            </div>
            <div className={`flex-none self-center ${snapshot.isDragging && 'invisible'}`}>
              {isEdit && <SaveIcon handleClick={handleEditName} />}
              <EditIcon handleClick={handleIsEdit} isEdit={isEdit} />
              <DeleteIcon handleClick={handleDelete} />
            </div>
          </div>
        </li>
      )}
    </Draggable>
  )
}
