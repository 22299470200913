/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import classNames from '../../common/helpers/classNames'
import { useSelector } from 'react-redux'

export default function GroupSelector() {
  const { groups } = useSelector((state) => state.recast)
  const [selected, setSelected] = useState(groups?.find((group) => group.is_active) ?? undefined)

  return <h3 className="text-center text-lg font-bold text-blue-500">{selected.name}</h3>

  // return (
  //   <Listbox value={selected} onChange={setSelected}>
  //     {({ open }) => (
  //       <>
  //         <div className="mt-1 w-30 ">
  //           <Listbox.Button className="relative z-20 w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
  //             <span className="block text-black truncate">{selected.name}</span>
  //             <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
  //               <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
  //             </span>
  //           </Listbox.Button>

  //           <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
  //             <Listbox.Options className="absolute z-30 w-3/4 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
  //               {groups.map((group) => (
  //                 <Listbox.Option
  //                   key={group.id}
  //                   className={({ active }) =>
  //                     classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-8 pr-4')
  //                   }
  //                   value={group}
  //                 >
  //                   {({ selected, active }) => (
  //                     <>
  //                       <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{group.name}</span>

  //                       {selected ? (
  //                         <span
  //                           className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5')}
  //                         >
  //                           <CheckIcon className="w-5 h-5" aria-hidden="true" />
  //                         </span>
  //                       ) : null}
  //                     </>
  //                   )}
  //                 </Listbox.Option>
  //               ))}
  //             </Listbox.Options>
  //           </Transition>
  //         </div>
  //       </>
  //     )}
  //   </Listbox>
  // )
}
