import ActiveIcon from './ActiveIcon'
import { SunIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from 'react-redux'
import { setTypeIsActive } from '../../redux/slices/recastSlice'

export default function CastTypeItem({ type, handleSelect, selectedCastTypeId, selectedCastGroupId, isGroupActive }) {
  const key = `${type.name}-${type.id}`
  const className = `p-4 cursor-pointer hover:bg-indigo-500 ${selectedCastTypeId === type.id ? 'bg-indigo-200' : ''}`

  const { groups, casts } = useSelector((state) => state.recast)

  const dispatch = useDispatch()

  /* ****************** JSX / Variables ****************** */
  const castGroup = groups.find((group) => group.cast_type_ids?.find((typeId) => typeId === type.id) && selectedCastGroupId === group.id)
  const isActive = !!castGroup
  const activeCastName = casts.find((cast) => cast.cast_type_id === type.id && castGroup?.cast_ids.includes(cast.id))?.name

  /* ****************** Functions ****************** */
  const handleActive = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    ev.currentTarget.blur()
    handleSelect(ev, type.id)
    dispatch(setTypeIsActive({ id: type.id, isActive: !isActive, selectedCastGroupId }))
  }

  /* ****************** Use Effects ****************** */

  return (
    <li key={key} className={className} data-cast-type-id={type.id} onClick={handleSelect}>
      <div className="flex" data-cast-type-id={type.id}>
        <div className="self-center flex-none" data-cast-type-id={type.id}>
          <ActiveIcon type="single" handleClick={handleActive} isActive={isActive} />
        </div>
        <div className="self-center flex-auto pl-4" data-cast-type-id={type.id}>
          <p className="font-medium" data-cast-type-id={type.id}>
            {type.name}
          </p>
          <p className="flex items-center text-sm text-gray-700" data-cast-type-id={type.id}>
            <SunIcon
              data-cast-type-id={type.id}
              className={`flex-shrink-0 mr-1.5 h-5 w-5 ${activeCastName && isGroupActive && 'text-green-500'} ${
                activeCastName && !isGroupActive && 'text-yellow-500'
              }`}
            />
            <span className="truncate" data-cast-type-id={type.id}>
              {activeCastName ? activeCastName : 'Unassigned'}
            </span>
          </p>
        </div>
      </div>
    </li>
  )
}
