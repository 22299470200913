import DeleteIcon from './DeleteIcon'
import { useState } from 'react'
import ActiveIcon from './ActiveIcon'
import EditIcon from './EditIcon'
import SaveIcon from './SaveIcon'
import { useDispatch } from 'react-redux'
import { editGroupName, removeGroup, setGroupIsActive } from '../../redux/slices/recastSlice'

export default function CastGroupItem({ group, handleSelect, selectedCastGroupId, isLast }) {
  const dispatch = useDispatch()

  const [isEdit, setIsEdit] = useState(false)
  const [newName, setNewName] = useState(undefined)

  /* ****************** JSX / Variables ****************** */
  const liClassName = `p-4 cursor-pointer hover:bg-indigo-500 ${selectedCastGroupId === group.id ? 'bg-indigo-200' : ''}`

  /* ****************** Functions ****************** */
  const handleDelete = (ev) => {
    dispatch(removeGroup(group.id))
  }

  const handleEditName = () => {
    const payload = {
      name: newName,
      id: group.id,
    }

    dispatch(editGroupName(payload))
  }

  const handleInputEnterPress = (ev) => {
    if (ev.key === 'Enter') {
      const payload = {
        name: newName,
        id: group.id,
      }

      dispatch(editGroupName(payload))
    }
  }

  const handleActive = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()

    dispatch(setGroupIsActive({ id: group.id, isActive: !group.is_active }))
  }

  const handleSelectCastGroup = (ev) => {
    handleSelect(Number(ev.target.getAttribute('data-group-id')))
  }

  const handleIsEdit = () => {
    setIsEdit((prevState) => !prevState)
  }

  // const handleInputBlur = () => {
  //   setIsEdit(false)
  // }

  const handleInputChange = (ev) => {
    setNewName(ev.currentTarget.value)
  }

  /* ****************** JSX ****************** */
  const castGroupNameJSX = isEdit ? (
    <input className="w-full px-2" placeholder={group.name} onChange={handleInputChange} onKeyPress={handleInputEnterPress} />
  ) : (
    group.name
  )

  /* ****************** Use Effects ****************** */

  /* ****************** Render ****************** */
  return (
    <li key={`${group.name}-${group.id}`} className={liClassName} data-group-id={group.id} onClick={handleSelectCastGroup}>
      <div className="flex" data-group-id={group.id}>
        <div className="flex-none" data-group-id={group.id}>
          <ActiveIcon iconType="triple" data-group-id={group.id} handleClick={handleActive} isActive={group.is_active} />
        </div>
        <div className={`self-center flex-auto pl-4 font-medium w-full`} data-group-id={group.id}>
          {castGroupNameJSX}
        </div>
        <div className="self-center flex-none" data-group-id={group.id}>
          {isEdit && <SaveIcon handleClick={handleEditName} />}
          <EditIcon handleClick={handleIsEdit} isEdit={isEdit} />
          {!group.is_active ? <DeleteIcon handleClick={handleDelete} /> : null}
        </div>
      </div>
    </li>
  )
}
