import { XIcon } from '@heroicons/react/outline'
import classNames from '../../common/helpers/classNames'

export default function DeleteIcon({ handleClick, isDisabled }) {
  return (
    <button
      type="button"
      disabled={isDisabled}
      className={classNames(
        isDisabled ? 'disabled:opacity-25 disabled:cursor-not-allowed' : '',
        'inline-flex items-center p-1 text-gray-400 border border-transparent rounded-full shadow-sm hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      )}
      onClick={handleClick}
    >
      <XIcon className="w-3 h-3" aria-hidden="true" />
    </button>
  )
}
