import { useState, useEffect, useCallback } from 'react'
import CastTypeItem from '../components/atoms/CastTypeItem'
import CastItem from '../components/atoms/CastItem'
import ReactDOM from 'react-dom'
import NewCastItem from '../components/atoms/NewCastItem'
import usePrevious from '../hooks/usePrevious'
import CastGroupItem from '../components/atoms/CastGroupItem'
import NewCastGroupItem from '../components/atoms/NewCastGroupItem'
import SelectPreviousSection from '../components/atoms/SelectPreviousSection'
import CastValueList from '../components/molecules/CastValueList'
import { useSelector } from 'react-redux'
import classNames from '../common/helpers/classNames'
import ResetDataConfirmModal from '../components/molecules/ResetDataConfirmModal'

export default function Dashboard() {
  const [selectedCastGroupId, setSelectedCastGroupId] = useState(null)
  const [selectedCastTypeId, setSelectedCastTypeId] = useState(null)
  const [selectedCastId, setSelectedCastId] = useState(null)
  const [casts, setCasts] = useState([])
  const [castValues, setCastValues] = useState([])
  const [showResetModal, setShowResetModal] = useState(false)

  const prevSelectedCastTypeId = usePrevious(selectedCastTypeId)
  const prevSelectedCastGroupId = usePrevious(selectedCastGroupId)

  /* ****************** Hooks ****************** */
  const { castTypes, groups, casts: castsData } = useSelector((state) => state.recast)

  /* ****************** Functions ****************** */
  const handleCastGroupSelect = useCallback((groupId) => {
    setSelectedCastGroupId(groupId)
  }, [])

  const handleCastTypeSelect = useCallback((ev, castTypeId) => {
    const id = Number(ev.target.getAttribute('data-cast-type-id')) ? Number(ev.target.getAttribute('data-cast-type-id')) : castTypeId
    setSelectedCastTypeId(id)
  }, [])

  /* ****************** Variables ****************** */
  // const activeGroup = castGroupsData?.find((group) => group.is_active) ?? []

  /* ****************** JSX ****************** */
  const castGroupsJSX = groups
    .filter((group) => group.id !== 1)
    .map((group) => (
      <CastGroupItem
        key={group.name}
        group={group}
        handleSelect={handleCastGroupSelect}
        selectedCastGroupId={selectedCastGroupId}
        isLast={groups.length === 1}
      />
    ))

  const castTypesJSX = castTypes.map((type) => {
    const isGroupActive = !!groups?.find((group) => group.id === selectedCastGroupId)?.is_active

    return (
      <CastTypeItem
        key={type.name}
        type={type}
        handleSelect={handleCastTypeSelect}
        selectedCastTypeId={selectedCastTypeId}
        selectedCastGroupId={selectedCastGroupId}
        isGroupActive={isGroupActive}
      />
    )
  })

  const castsJSX = casts.map((cast) => (
    <CastItem
      key={cast.name}
      cast={cast}
      handleSelect={setSelectedCastId}
      selectedCastId={selectedCastId}
      selectedCastGroupId={selectedCastGroupId}
      selectedCastTypeId={selectedCastTypeId}
    />
  ))

  const castTypesListJSX = (
    <div className="overflow-hidden bg-white rounded-md shadow">
      <ul className="divide-y divide-gray-200">{castTypesJSX}</ul>
    </div>
  )

  const castListJSX = (
    <div className="overflow-hidden bg-white rounded-md shadow">
      <ul className="divide-y divide-gray-200">
        {castsJSX}
        <NewCastItem selectedCastTypeId={selectedCastTypeId} />
      </ul>
    </div>
  )

  /* ****************** UseEffects ****************** */
  useEffect(() => {
    // handle changing cast and cast values
    if (selectedCastTypeId && prevSelectedCastTypeId !== selectedCastTypeId) {
      const existingCasts = castsData.filter((cast) => cast.cast_type_id === selectedCastTypeId)
      ReactDOM.unstable_batchedUpdates(() => {
        setCastValues(null)
        setCasts(null)
        setSelectedCastId(null)
        setCasts(existingCasts)
      })
    }

    // handling changing cast values
    if (selectedCastTypeId && prevSelectedCastTypeId === selectedCastTypeId) {
      const existingCasts = castsData.filter((cast) => cast.cast_type_id === selectedCastTypeId)
      setCasts(existingCasts)
    }
  }, [castsData, prevSelectedCastTypeId, selectedCastTypeId])

  // Reset selected id's when a group id changes
  useEffect(() => {
    if (selectedCastGroupId && prevSelectedCastGroupId !== selectedCastGroupId) {
      ReactDOM.unstable_batchedUpdates(() => {
        setSelectedCastId(null)
        setSelectedCastTypeId(null)
      })
    }
  }, [prevSelectedCastGroupId, selectedCastGroupId])

  // Updated the cast values when a selected cast id exists
  useEffect(() => {
    if (selectedCastId) {
      const existingCastValues = casts.find((cast) => cast.id === selectedCastId)
      setCastValues(existingCastValues?.cast_values ?? [])
    }
  }, [casts, selectedCastId])

  /* ****************** Render ****************** */
  return (
    <>
      <div className="mx-auto mb-6 max-w-screen-2xl flex justify-between">
        <h1 className="text-2xl font-semibold text-slate-800">Case Management</h1>
        <div className="font-semibold">
          {<span className={classNames(groups[0].is_active ? 'text-blue-500' : 'text-red-500')}>{groups[0].is_active ? 'Active' : 'Deactive'}</span>}{' '}
          Golden Data Set
        </div>
        <div>
          <ResetDataConfirmModal show={showResetModal} setShowResetModal={setShowResetModal} />
          <button
            type="button"
            onClick={() => setShowResetModal(!showResetModal)}
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Reset ReCasts
          </button>
        </div>
      </div>
      {/* <ActiveGroupDetails activeGroup={activeGroup} /> */}

      <div className="grid grid-cols-4 gap-4">
        <div>
          <h2 className="mb-2">
            <span>1.</span> Groups
          </h2>
          <div className="overflow-hidden bg-white rounded-md shadow">
            <ul className="divide-y divide-gray-200">
              {castGroupsJSX}
              <NewCastGroupItem />
            </ul>
          </div>
        </div>
        <div>
          <h2 className="mb-2">
            <span>2.</span> Cast Types
          </h2>
          {selectedCastGroupId ? castTypesListJSX : <SelectPreviousSection title={'Select a group to load cast types'} message={''} />}
        </div>
        <div>
          <h2 className="mb-2">
            <span>3.</span> Casts
          </h2>
          {selectedCastTypeId ? castListJSX : <SelectPreviousSection title={'Select a cast type to load casts'} message={''} />}
        </div>
        <div>
          <h2 className="mb-2">
            <span>4.</span> Cast Values
          </h2>
          {selectedCastId ? (
            <CastValueList castValues={castValues} selectedCastId={selectedCastId} />
          ) : (
            <SelectPreviousSection title={'Select a cast to load cast values'} message={''} />
          )}
        </div>
      </div>
    </>
  )
}
