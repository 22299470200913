import React from 'react'
import Dashboard from '../pages/Dashboard'
import { Routes, Route } from 'react-router-dom'

import Nav from '../components/organisms/Nav'
import DemoPageOne from '../pages/DemoPageOne'

function App() {
  /* ****************** Functions ****************** */

  /* ****************** Use Effects ****************** */

  /* ****************** Render ****************** */
  return (
    <Nav>
      <Routes>
        <Route path="/" element={<DemoPageOne />} />
        <Route path="/recasts" element={<Dashboard />} />
      </Routes>
    </Nav>
  )
}

export default App
