export const gdsSampleSurgeTopics = [
  'Cloud Solution',
  'AWS',
  'Digital Media',
  'Machine Learning',
  'AI',
  'Prime Video',
  'Virtualization',
  'Cybersecurity',
  'SOC2',
  'GDPR',
]

/* ****************** Top Rate - Sales ****************** */
export const gdsConverationRatePeopleName = ['Artem', 'Brian', 'Emma', 'Peter', 'George']
export const gdsConverationRate = ['88', '78', '77', '78', '65']

/* ****************** Active Contacts ****************** */
export const gdsActiveUserNames = ['Jody Barber', 'Lindsay Collier', 'Wilma Morgan', 'Gregory Phillips']
export const gdsActiveUserEmails = ['jbarber@google.com', 'lindsayc@meta.com', 'wmorgan@google.com', 'gphillips@tesla.com']
export const gdsActiveUserTitles = ['VP Engineering', 'Director, Product', 'GVP, Sales', 'VP, Sales']

/* ****************** Open Opportunities ****************** */
export const gdsOpportuntiesCompany = ['Google', 'Amazon', 'Apple', 'Tesla', 'Disney', 'Dell', 'Meta', 'Microsoft']
export const gdsOpportuntiesCustomerName = [
  'Wade Walton',
  'Felicia Carlson',
  'Dwight Page',
  'Wilson Wheeler',
  'Ginger Andrews',
  'Veronica Ellis',
  'Victoria Ray',
  'Todd Oliver',
]
export const gdsOpportuntiesTotalSales = ['55,000', '62,300', '78,000', '100,000', '32,000', '30,000', '71,000', '54,000']
export const gdsOpportuntiesStatus = ['MQL', 'Tech Review', 'Contracting', 'Contracting', 'Discovery', 'POV', 'SQL', 'MQL']
